import { axios } from '@/utils/request'

const basic = '/manage/explicit'
const api = {
  // 分页查询外显号码
  findExplicit: basic + '/findExplicit',
  // 查询所有外显号码
  findAllExplicit: basic + '/findAllExplicit',
  // 添加外显号码
  addExplicit: basic + '/addExplicit',
  // 删除外显号码
  delExplicit: basic + '/delExplicit'
}
export function findExplicit (parameter) {
  return axios({
    url: api.findExplicit,
    method: 'post',
    params: parameter
  })
}
export function addExplicit (parameter) {
  return axios({
    url: api.addExplicit,
    method: 'post',
    params: parameter
  })
}
export function delExplicit (parameter) {
  return axios({
    url: api.delExplicit,
    method: 'post',
    params: parameter
  })
}
