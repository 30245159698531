<template>
  <a-modal
    :title="editModalTitle"
    :maskClosable="false"
    v-model="visible"
    :confirmLoading="confirmLoading"
    @ok="editHandleOk"
    @cancel="editHandleCancel"
    width="550px"
    class="edit-permission-component"
  >
    <a-form-model
      ref="ruleForm"
      :model="form"
      :rules="rules"
      :label-col="{span:4}"
      :wrapper-col="{span:20}"
    >
      <a-form-model-item label="外显号码" prop="phone">
        <a-input v-model.number="form.phone" type="number"/>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import { addExplicit } from '@/api/template/explicit'

export default {
  name: 'EditExplicitComponent',
  props: {
    showEditModal: Boolean,
    editData: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  watch: {
    showEditModal: function (newVal, oldVal) {
      this.visible = newVal
      this.createForm()
    },
    editData: function (newVal, oldVal) {
      this.createForm()
      this.newEditData = newVal
      console.log(this.newEditData)
      this.form.phone = this.newEditData.phone
      console.log(this.form.phone)
      // 未传递edit对象，则为新增
      if (Object.keys(this.newEditData).length === 0) {
        this.editModalTitle = '新增权限'
        this.disabledNameInput = false
      } else {
        this.editModalTitle = '编辑权限'
        this.disabledNameInput = false
      }
    }
  },
  data () {
    return {
      disabledNameInput: false,
      topPermissionsList: [],
      iconType: 'edit',
      confirmLoading: false,
      visible: false,
      form: {
        phone: null
      },
      rules: {
        phone: [
          { required: true, message: '外显号码不能为空', trigger: 'blur' }
        ]
      },
      newEditData: {},
      editModalTitle: '新增权限'
    }
  },
  beforeCreate () {
    if (this.form === null) {
      this.$refs.ruleForm.resetFields()
    }
  },
  created () {
    this.createForm()
    // 未传递edit对象，则为新增
    if (Object.keys(this.editData).length === 0) {
      this.editModalTitle = '新增外显号码'
      this.disabledNameInput = false
    } else {
      this.editModalTitle = '编辑外显号码'
      this.disabledNameInput = true
    }
  },
  methods: {
    createForm () {
      if (this.form === null) {
        this.$refs.ruleForm.resetFields()
      }
    },
    editHandleCancel () {
      this.$refs.ruleForm.resetFields()
      this.$emit('cancel')
    },
    editPermissionSubmit (values) {
      if (this.newEditData && this.newEditData.id) {
        values.id = this.newEditData.id
      }
      return addExplicit(values)
    },
    editHandleOk () {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.editPermissionSubmit(this.form).then(res => {
            if (res.status !== 200) {
              this.$notification.error({
                message: '提示',
                description: res.message
              })
              return
            }
            this.$emit('ok')
            this.$notification.success({
              message: '提示',
              description: res.message
            })
            this.$refs.ruleForm.resetFields()
          }).catch((e) => {
            this.$notification.error({
              message: '提示',
              description: e.message
            })
          }).finally(() => {
            this.confirmLoading = false
          })
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
